import React, { FC } from 'react';

// Components
import { EditProperty } from 'components';

// Types:
import { Category, Description, Subtype, Type } from 'types/Device';

import { Category as FullCategory } from 'types/Category';

interface Props {
  categories: FullCategory[];
  category: Category;
  code: string;
  description: Description;
  deviceSubtype: Subtype;
  moneyCode: string;
  parentId: string;
  position: number;
  price: number;
  priceM: number;
  priceM2: number;
  setCategory: (cat: Category) => void;
  setCode: (code: string) => void;
  setDescription: (desc: Description) => void;
  setDeviceSubtype: (subtype: Subtype) => void;
  setMoneyCode: (mC: string) => void;
  setParentId: (id: string) => void;
  setPosition: (pos: number) => void;
  setPrice: (pr: number) => void;
  setPriceM: (pr: number) => void;
  setPriceM2: (pr: number) => void;
  setStyle: (style: string) => void;
  setType: (type: Type) => void;
  style: string;
  type: Type;
}

const General: FC<Props> = ({
  categories,
  category,
  code,
  description,
  deviceSubtype,
  moneyCode,
  parentId,
  position,
  price,
  priceM,
  priceM2,
  setCategory,
  setCode,
  setDescription,
  setDeviceSubtype,
  setMoneyCode,
  setParentId,
  setPosition,
  setPrice,
  setPriceM,
  setPriceM2,
  setStyle,
  setType,
  style,
  type
}) => {
  return (
    <>
      <h3 className="Device-SubCategory-Header">General</h3>
      <div className="Device-SubCategory">
        <EditProperty description="Category:">
          {type === Type.Equipment || type === Type.Option ? (
            <div>-</div>
          ) : (
            <form action="">
              <select
                className="Device-SubCategory-Selectbox"
                onChange={event => {
                  setCategory({
                    id: event.target.value
                  });
                }}
                value={category.id}
              >
                {categories &&
                  categories.length > 0 &&
                  categories.map((c: FullCategory) => (
                    <option key={`${c.id}-CategoryOption`} value={c.id}>
                      {/* TODO: Implement default language, replace vdrqR */}
                      {c.translations['vdrqR'].name}
                    </option>
                  ))}
              </select>
            </form>
          )}
        </EditProperty>
        <EditProperty description="Code:">
          <input
            className="EditProperty-Input-Textfield"
            onChange={event => {
              setCode(event.target.value);
            }}
            placeholder="Enter Code"
            value={code}
          />
        </EditProperty>
        <EditProperty description="Money Code:">
          <input
            className="EditProperty-Input-Textfield"
            onChange={event => {
              setMoneyCode(event.target.value);
            }}
            placeholder="Enter Money Code"
            value={moneyCode}
          />
        </EditProperty>
        <EditProperty description="Type:">
          <form action="">
            <select
              onChange={event => {
                const index = parseInt(event.target.value);
                if (index < 1) {
                  setType(Type.Device);
                } else if (index === 1) {
                  setType(Type.Equipment);
                } else {
                  setType(Type.Option);
                }
              }}
              id="Device-Type-Selectbox"
              value={type}
            >
              <option value={Type.Device}>Device</option>
              <option value={Type.Equipment}>Equipment</option>
              <option value={Type.Option}>Option</option>
            </select>
          </form>
        </EditProperty>
        {(type === Type.Equipment || type === Type.Option) && (
          <EditProperty description="Subtype:">
            <form action="">
              <select
                onChange={event => {
                  setDeviceSubtype(Subtype[event.target.value]);
                }}
                value={Subtype[deviceSubtype]}
              >
                <option value={Subtype.WingedDoor}>Winged Door</option>
                <option value={Subtype.Hygiene}>Hygiene</option>
                <option value={Subtype.AngularRail}>Angular Rail</option>
                <option value={Subtype.UpperDrawer}>Upper Drawer</option>
                <option value={Subtype.LowerDrawer}>Lower Drawer</option>
                <option value={Subtype.HeatingCabinet}>Heating Cabinet</option>
                <option value={Subtype.HeatingDrawer}>Heating Drawer</option>
                <option value={Subtype.UpperPowerSocket}>Upper Power Socket</option>
                <option value={Subtype.MixingFaucet}>Mixing Faucet</option>
                <option value={Subtype.EmptyingSystem}>Emptying System</option>
                <option value={Subtype.EOven}>E Oven</option>
                <option value={Subtype.GOven}>G Oven</option>
                <option value={Subtype.HandRail}>Hand Rail</option>
                <option value={Subtype.Shelf}>Shelf</option>
                <option value={Subtype.Bottom}>Bottom</option>
                <option value={Subtype.Border}>Border</option>
                <option value={Subtype.Calculation}>Calculation</option>
                <option value={Subtype.Underframe850}>Underframe 850</option>
                <option value={Subtype.Underframe580}>Underframe 580</option>
                <option value={Subtype.Cooling}>Cooling</option>
                <option value={Subtype.OpenSubstructure}>Open Substructure</option>
                <option value={Subtype.WasteDrawer}>Waste Drawer</option>
                <option value={Subtype.ColdWaterInlet}>Cold Water Inlet</option>
                <option value={Subtype.DoubleSidedOperation}>Double Sided Operation</option>
                <option value={Subtype.ShelfMixingFaucet}>Shelf Mixing Faucet</option>
                <option value={Subtype.InstallationWall}>Installation Wall</option>
                <option value={Subtype.BlindCover}>Blind Cover</option>
                <option value={Subtype.BaseCover}>Base Cover</option>
                <option value={Subtype.FlexiCombiAir}>Flexi Combi Air</option>
                <option value={Subtype.MagicHood}>Magic Hood</option>
                <option value={Subtype.SpaceClean}>Space Clean</option>
                <option value={Subtype.GNFrame}>GN Frame</option>
                <option value={Subtype.ReadyXpress}>Ready Xpress</option>
                <option value={Subtype.WarmingDrawer}>Warming Drawer</option>
                <option value={Subtype.WarmingDevice}>Warming Device</option>
                <option value={Subtype.IntermediatePlate}>Intermediate Plate</option>
                <option value={Subtype.FlexiCombiDoor}>FlexiCombiDoor</option>
                <option value={Subtype.HasFixedSubstructure}>HasFixedSubstructure</option>
                <option value={Subtype.PremiumKnob}>PremiumKnob</option>
              </select>
            </form>
          </EditProperty>
        )}
        <EditProperty description="Style:">
          <input
            onChange={event => {
              setStyle(event.target.value);
            }}
            value={style}
            className="EditProperty-Input-Textfield"
            placeholder="Enter Style"
          />
        </EditProperty>
        <EditProperty description="Position:">
          <input
            className="EditProperty-Input-Textfield"
            onChange={event => {
              setPosition(parseInt(event.target.value));
            }}
            placeholder="Enter Position"
            type="number"
            value={position}
          />
        </EditProperty>
        <EditProperty description="Parent ID:">
          <input
            className="EditProperty-Input-Textfield"
            onChange={event => {
              setParentId(event.target.value);
            }}
            placeholder="Enter Parent ID (for NOL Variants)"
            value={parentId}
          />
        </EditProperty>
      </div>
      <h3 className="Device-SubCategory-Header">Description</h3>
      <div className="Device-SubCategory">
        <EditProperty description="Norm:">
          <textarea
            className="EditProperty-Input-Textarea"
            onChange={event => {
              setDescription({
                ...description,
                norm: event.target.value
              });
            }}
            placeholder="Enter Norm"
            value={description ? description.norm : ''}
          />
        </EditProperty>
        <EditProperty description="Name 700:">
          <textarea
            className="EditProperty-Input-Textarea"
            onChange={event => {
              setDescription({
                ...description,
                name700: event.target.value
              });
            }}
            placeholder="Enter Name 700"
            value={description ? description.name700 : ''}
          />
        </EditProperty>
        <EditProperty description="Name 850:">
          <textarea
            className="EditProperty-Input-Textarea"
            onChange={event => {
              setDescription({
                ...description,
                name850: event.target.value
              });
            }}
            placeholder="Enter Name 850"
            value={description ? description.name850 : ''}
          />
        </EditProperty>
      </div>
      <h3 className="Device-SubCategory-Header">Price</h3>
      <div className="Device-SubCategory">
        <EditProperty description="Price">
          <input
            className="EditProperty-Input-Textfield"
            onChange={event => {
              setPrice(parseFloat(event.target.value));
            }}
            placeholder="Price"
            type="number"
            value={price}
          />
        </EditProperty>
        <EditProperty description="Price m">
          <input
            className="EditProperty-Input-Textfield"
            onChange={event => {
              setPriceM(parseFloat(event.target.value));
            }}
            placeholder="Price m"
            type="number"
            value={priceM}
          />
        </EditProperty>

        <EditProperty description="Price m2">
          <input
            className="EditProperty-Input-Textfield"
            onChange={event => {
              setPriceM2(parseFloat(event.target.value));
            }}
            placeholder="Price m2"
            type="number"
            value={priceM2}
          />
        </EditProperty>
      </div>
    </>
  );
};

export default General;
