import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactTable from 'react-table';

// Components
import { Button, Dialog, LoadingSpinner, RadioButton } from 'components';

// Styles:
import './style.css';
import 'react-table/react-table.css';

// Utils:
import { post } from 'utils/AJAX';
import authContext from '../../context/auth-context';
import { Right } from 'types/Member';
import { useSyncAllDevices } from '../../utils/useSyncAllDevices';
import { UploadExcel } from 'components/UploadExcel';

// =========================================================
interface Props extends RouteComponentProps {}

type DeviceType = 'masterline' | 'modular' | 'spaceCombi' | 'flexiChef' | 'marineMeister' | 'modularNOL' | '' | null;
// =========================================================

const Devices: FC<Props> = ({ history }) => {
  const ROWS = 11;
  const { userRights, canEditMasterline, canEditModular } = useContext(authContext);

  const getDefaultDeviceType = (rights: Right[]) => {
    if (!rights) return null;
    const masterlineRight = rights.includes('EditMasterline');
    const modularRight = rights.includes('EditModular');
    if (masterlineRight && modularRight) return '';
    if (masterlineRight) return 'masterline';
    if (modularRight) return 'modular';
    return null;
  };

  const { syncAllDevices, syncError, syncing, setShowSyncAllDialog, showSyncAllDialog } = useSyncAllDevices();

  const [devices, setDevices] = useState([]);
  const [deviceType, setDeviceType] = useState<DeviceType>(getDefaultDeviceType(userRights));
  const [shouldHideVariantDevices, setShouldHideVariantDevices] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState(ROWS);

  // =================================

  const columns = [
    {
      Header: 'Name',
      id: 'NameCol',
      accessor: (d: any) => {
        return d.translations.vdrqR.name;
      }
    },
    { Header: 'Code', accessor: 'code' },
    {
      Header: 'Depths',
      id: 'DepthsCol',
      accessor: (d: any) => {
        return d?.model?.depths?.join(', ') || '';
      }
    }
  ];

  // =================================

  const fetchDevices = useCallback(async () => {
    setLoading(true);
    const { data, error, count } = await post(`${process.env.REACT_APP_API_URL}/device/search`, {
      data: {
        rows: ROWS,
        offset: offset,
        query: query,
        sort: {
          field: 'code',
          order: 'ASC'
        },
        basicOnly: shouldHideVariantDevices,
        type: deviceType
      }
    });
    if (count) {
      setResults(count);
    }
    if (data) {
      // if (offset >= 1) setDevices([...devices, ...data]);
      // else setDevices(data);
      setDevices(data);
    }
    if (error) {
      console.log(error);
    }
    setLoading(false);
  }, [deviceType, offset, query, shouldHideVariantDevices]);

  useEffect(() => {
    fetchDevices();
  }, [offset]);

  useEffect(() => {
    setOffset(0);
  }, [query]);

  return (
    <div className="Devices">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <h1>Devices</h1>
        </div>
      )}

      <div className="Devices-Options">
        <Button btnType="second" onClick={() => history.push('/devices/new')}>
          Create Device
        </Button>
        <hr style={{ width: '100%', opacity: 0.5, margin: '1.5rem 0' }} />
        <div className="Devices-Options-Searchform">
          <form
            onSubmit={e => {
              e.preventDefault();
              fetchDevices();
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', textAlign: 'left' }}>
              <label style={{ fontSize: '0.85rem' }}>Device Line</label>
              <select
                onChange={event => {
                  setDeviceType(event.target.value as DeviceType);
                }}
                id="Device-Type-Selectbox"
                value={deviceType}
              >
                {canEditMasterline && <option value={'masterline'}>Masterline</option>}
                {canEditMasterline && <option value={'marineMeister'}>Marinemeister</option>}
                {canEditModular && <option value={'modular'}>Modular</option>}
                {canEditModular && <option value={'modularNOL'}>Modular NOL</option>}
                {canEditModular && <option value={'flexiChef'}>FlexiChef</option>}
                {canEditModular && <option value={'spaceCombi'}>Kombidämpfer</option>}
                {Array.isArray(userRights) && userRights.includes('EditMasterline') && userRights.includes('EditModular') && <option value="">All</option>}
              </select>
            </div>
            <input
              className="Devices-Options-Searchform-Textfield"
              onChange={event => {
                setQuery(event.target.value);
              }}
              placeholder="Search Device"
              type="search"
              value={query}
            />

            <div className="Device-Checkboxes-Wrapper" style={{ display: 'flex', margin: '.25rem 0 .75rem 0', flexShrink: 0, alignItems: 'center' }}>
              <input checked={shouldHideVariantDevices} id="show-base-only-check" onChange={() => setShouldHideVariantDevices(prev => !prev)} type="checkbox" />
              <label htmlFor="show-base-only-check">Show Base only</label>
            </div>

            <Button btnType="first" type="submit" style={{ marginTop: '1rem' }}>
              Search
            </Button>
          </form>
        </div>
      </div>
      <div className="Devices-Content">
        <ReactTable
          columns={columns}
          data={devices}
          resolveData={data => {
            const formatted = [];
            for (let index = 0; index < offset; index++) {
              if (index < offset) {
                formatted.push({ translations: { vdrqR: { name: '' } } });
              }
            }
            formatted.push(...data);
            if (query === '') {
              for (let index = formatted.length; index < results; index++) {
                formatted.push({ translations: { vdrqR: { name: '' } } });
              }
            }
            return formatted;
          }}
          defaultPageSize={10}
          showPageSizeOptions={false}
          getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              onClick: (e: any, handleOriginal: any) => {
                if (rowInfo) {
                  window.open(`/devices/${rowInfo.original.id}`);
                  //history.push(`/devices/${rowInfo.original.id}`);
                }
              }
            };
          }}
          onPageChange={page => {
            if (offset <= results) {
              setOffset(page * 10);
            }
            fetchDevices();
          }}
        />
      </div>
      <br />
      <br />
      <br />
      <div className="Devices-Import">
        <UploadExcel url={'/device/import/prices'}>Upload Prices list</UploadExcel>
        <UploadExcel url={'/device/import/translations'} buttonClassName="Translation">
          Upload Translation list
        </UploadExcel>
      </div>
      <br />

      <Button btnType="delete" disabled={syncing} onClick={() => setShowSyncAllDialog(true)}>
        {syncing ? <LoadingSpinner size="1rem" margin={'0.125rem auto'} /> : 'Sync all'}
      </Button>
      <Dialog
        heading="Attention!"
        open={showSyncAllDialog}
        onAbort={() => {
          setShowSyncAllDialog(false);
        }}
        onConfirm={() => {
          syncAllDevices();
        }}
        text="Are you sure you want to synchronize ALL devices? This action can not be undone."
      />
    </div>
  );
};

export default Devices;
