import axios, { AxiosRequestConfig } from 'axios';

// ==============================================
// GET

/**
 * Fetch data from given URL.
 * @param {string} url
 * @param {object} options additional options (optional)
 * @returns response.data if successful, else false
 */
export const get = async (
  url: string,
  {
    auth,
    method = 'GET',
    params = {},
    responseType = 'json',
    withCredentials = true,
    headers = {
      jwt: window.localStorage.getItem('jwt-mkn-admin')
    },
    timeout
  }: AxiosRequestConfig = {}
) => {
  try {
    const response = await axios({
      auth,
      method,
      params,
      responseType,
      url,
      withCredentials,
      headers,
      timeout
    });

    console.log({ response });

    return responseType === 'blob'
      ? { data: response.data }
      : {
          count: response && response.data && response.data.resultsSize ? response.data.resultsSize : 0,
          data:
            response && response.data && response.data.results
              ? response.data.results
              : response && response.data && response.data.result
              ? response.data.result
              : []
        };
  } catch (error) {
    return {
      error: error
    };
  }
};

// ==============================================
// POST

/**
 * Fetch data from given URL.
 * @param {string} url
 * @param {object} options additional options (optional)
 * @returns response.data if successful, else false
 */
export const post = async (
  url: string,
  {
    auth,
    data = {},
    headers = {
      jwt: window.localStorage.getItem('jwt-mkn-admin')
    },
    params = {},
    withCredentials = true
  }: AxiosRequestConfig = {}
) => {
  try {
    const response = await axios({
      auth,
      data,
      headers,
      method: 'post',
      params,
      url,
      withCredentials
    });
    return {
      count: response && response.data && response.data.resultsSize ? response.data.resultsSize : 0,
      data:
        response && response.data && response.data.results
          ? response.data.results
          : response && response.data && response.data.result
          ? response.data.result
          : []
    };
  } catch (error) {
    return {
      error: error
    };
  }
};

// ==============================================
// PUT

/**
 * Fetch data from given URL.
 * @param {string} url
 * @param {object} options additional options (optional)
 * @returns response.data if successful, else false
 */
export const put = async (url: string, { auth, data = {}, params = {}, withCredentials = true }: AxiosRequestConfig = {}) => {
  try {
    const response = await axios({
      auth,
      data,
      method: 'put',
      params,
      url,
      withCredentials
    });
    return {
      count: response && response.data && response.data.resultsSize ? response.data.resultsSize : 0,
      data:
        response && response.data && response.data.results
          ? response.data.results
          : response && response.data && response.data.result
          ? response.data.result
          : []
    };
  } catch (error) {
    return {
      error: error
    };
  }
};
